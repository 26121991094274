<template>
    <base-section id="theme-features" space="0">
        <base-section-heading title="Working With Us">
            We bring years of industry experience, a strong technical team, and
            heart and passion to serve people to build innovative solutions for
            the rental market.
        </base-section-heading>

        <v-container>
            <v-row>
                <v-col
                    v-for="(feature, i) in features"
                    :key="i"
                    cols="12"
                    md="6"
                >
                    <base-avatar-card v-bind="feature" align="left" horizontal>
                        {{ feature.text }}
                    </base-avatar-card>
                </v-col>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
export default {
    name: "SectionThemeFeatures",

    data: () => ({
        features: [
            {
                title: "Design Prototyping",
                icon: "mdi-application",
                text:
                    "We won't just provide a photoshop image, our prototypes are interactive, built rapidly and easily iterated",
            },
            {
                title: "Mobile Ready",
                icon: "mdi-cellphone",
                text:
                    "We optimize all of our applications for easy access from phones and tablets. We start with a mobile first design in mind.",
            },
            {
                color: "primary",
                dark: true,
                title: "Multi-Language Support",
                icon: "mdi-flag",
                text:
                    "We build our websites and applications for to be accessible by english as a second language speaking applicants.",
            },
            {
                title: "Accessibility",
                icon: "mdi-account-supervisor",
                text:
                    "We adhere to website guidelines and standards for accessibility.",
            },
            {
                title: "Creative",
                icon: "mdi-palette",
                text:
                    "We have pioneered creative solutions to reduce barriers for tenants. We are always thinking of new ways to just make it easier.",
            },
            {
                title: "24/7 Support",
                icon: "mdi-help-circle-outline",
                text:
                    "We provide the highest quality support in the industry with attentive, experienced staff.",
            },
        ],
    }),
};
</script>
